<template >
<Layout>
    <PageHeader :title="title" :items="items" />
      <div class="alertMsg">
      <b-alert v-model="showDismissibleAlert" variant="success" dismissible>
       {{alertMsg}}
    </b-alert>
    </div>
    
<form @submit.prevent="submitPrincipleBrachForm" method="post">
    <div class="row">
        <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
            <div class="row">
                 <div class="col-md-6 mb-3">
                     <label>Choose Principle *</label>
                    <multiselect v-model="principleID" :options="principles" track-by="principleID" label="principleName"
                     @input="onchangePrinciple();" :class="{
                        'is-invalid': submitted && $v.principleID.$error,
                      }" ></multiselect>
                       <div
                      v-if="submitted && $v.principleID.$error"
                      class="invalid-feedback"
                    >
                      <span v-if="!$v.principleID.required"
                        >This value is required.</span
                      >
                    </div>
                </div>

                 <div class="col-md-6 mb-3">
                     
                        <label>Location *</label>
                          <multiselect v-model="posts.branchID" :options="branches" track-by="branchID" label="branchName"  :class="{
                        'is-invalid': submitted && $v.posts.branchID.$error,
                      }"></multiselect>
                        <div
                          v-if="submitted && $v.posts.branchID.$error"
                          class="invalid-feedback"
                        >
                          <span v-if="!$v.posts.branchID.required"
                            >This value is required.</span
                          >
                        </div>
                </div>

            </div>
        
            <div class="row">
                  <div class="mb-3 col-md-6">
                    <label>Address
                    </label>
                      <vue-google-autocomplete
                              ref="address"
                              id="map"
                              classname="form-control"
                              placeholder="Please type your address"
                              v-on:placechanged="getAddressData"
                              country="in"
                              types="establishment"
                              v-model="posts.address"
                              autocomplete="off"
                               :class="{
                                'is-invalid': submitted && $v.posts.addressInput.$error,
                              }"
                            
                          >
                          </vue-google-autocomplete>
                          <div
                          v-if="submitted && $v.posts.addressInput.$error"
                            class="invalid-feedback"
                          >
                            <span v-if="!$v.posts.addressInput.required"
                              >This value is required.</span
                            >
                          </div>

                        
                      </div>
                      <div v-if="this.posts.addressInput" class="col-md-4" style="border: 1px solid #d8d8d8;padding: 10px 28px;">
                       
                          <p>{{this.posts.addressInput}}
                              
                               <span style="position: absolute;right: 3px;bottom: -7px;">
                              <button type="button" v-b-modal.modal-standard class="btn" 
                              style="color: #F3766A !important;font-size: 15px;font-weight: 600;">
                                    <i class="uil uil-plus"></i> Edit
                              </button>
                              </span>
                              </p>
                      </div>
                      
                </div>

            <b-modal
                id="modal-standard"
                title="Address"
                title-class="font-18"
                @ok="addNewAddress();"
              >
               <div class="mb-4">
               <label>Address</label>
                  <textarea
                    v-model="posts.addressInput"
                    class="form-control"
                    name="textarea"
                    rows="3"
                  ></textarea>
                </div>
                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-pincode-input">Pincode</label>
                          <input type="text" class="form-control" id="formrow-pincode-input" v-model="posts.pincode"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-city-input">City</label>
                          <input type="text" class="form-control" id="formrow-city-input" v-model="posts.city"
                         
                          >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-state-input">State</label>
                          <input type="text" class="form-control" id="formrow-state-input" v-model="posts.state"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-country-input">Country</label>
                          <input type="text" class="form-control" id="formrow-country-input" v-model="posts.country"
                         
                          >
                    </div>
                </div>

                <div class="row">
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-latitude-input">Latitude</label>
                          <input type="text" class="form-control" id="formrow-latitude-input" v-model="posts.latitude"
                         
                          >
                    </div>
                    <div class="col-md-6 mb-3">
                          <label class="form-label" for="formrow-longitude-input">Longitude</label>
                          <input type="text" class="form-control" id="formrow-longitude-input" v-model="posts.longitude"
                         
                          >
                    </div>
                </div>

              </b-modal>
           

                <div class="row mb-3">
                        <div class="mb-3 col-6">
                          <label class="form-label" for="formrow-gstNo-input">GST Number</label>
                          <input type="text" class="form-control"  v-model="posts.gstNO">
                      </div>

                    <div class="mb-3 col-6">
                        <label class="form-label" for="formrow-gstName-input">Registered Name as per GST</label>
                        <input type="text" class="form-control"  v-model="posts.gstName">
                    </div>
                </div>
                <div class="mb-3 col-6">
                          <label class="form-label" for="formrow-gstNo-input">Unique Identifier</label>
                          <input type="text" class="form-control"  v-model="posts.uniqueIdentifier">
                </div>

            </div>
            </div>
        </div>
    </div>

    <div class="col-lg-12">
            <div class="card">
                <div class="card-body">
                  <h6 class="mb-3"> Sub-Corporates </h6>
                    <div class="mb-3 col-md-6 row">
                      <div class="col-md-6">
                          <label class="form-label" for="formrow-firstname-input">Select Sub-Corporates *</label><br>
                         <button type="button" class="btn btn-themeOrange  w-md waves-effect waves-light " @click="openModal();">Corporate Branches
                           </button> 
                      </div>
                                    <p class="col-md-6" :class="{
                                    'is-invalid': submitted && $v.selected.$error,
                                      }">
                                      Selected branches:<br>
                                      {{ selected.length }}
                                     
                                    </p>
                                      <div
                                          v-if="submitted && $v.selected.$error"
                                          class="invalid-feedback"
                                          >
                                          <span v-if="!$v.selected.required"
                                              >This value is required.</span
                                          >
                                          </div>
        
                      </div>

                       <div class =" row" style="float:right;">
                          <b-spinner v-if="loading"
                          class="m-2 col-3"
                          variant="primary"
                          role="status"
                        ></b-spinner>
                        <button type="submit" v-if="!isEdit && $can('create principle branch')" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                        <button type="submit" v-if="isEdit && $can('update principle branch')" class="col-3 btn btn-themeYellow w-md waves-effect waves-light" :disabled="loading">Submit</button>
                    </div>
                </div>
            </div>
    </div>
 <b-modal
                id="modalBranches"
                title="Corporate Branches"
                title-class="font-18"
                
               size="lg"
              >
                              <!-- Table -->
            <div class="table-responsive mb-0">
              <h6>Corporate Branches:</h6>
                  <p>
                    <b-button size="sm" class="me-2" @click="selectAllRows">Select all</b-button>
                    <b-button size="sm" @click="clearSelected">Clear selected</b-button>
                    
                </p>
              <b-table
              
                striped hover
                outlined
                bordered
               
                :items="tableData"
                :fields="fields"
                :select-mode="selectMode"
                ref="selectableTable"
                selectable
               
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
              <template v-slot:cell(selected)="data"> 
                 <input class="form-check-input" type="checkbox"  :value="data.item.corpBranchID" v-model="selected">
               </template>
</b-table>
            </div>
            <div class="row">
              <div class="col">
                <div
                  class="dataTables_paginate paging_simple_numbers float-end"
                >
                  <ul class="pagination pagination-rounded mb-0">
                    <!-- pagination -->
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                    ></b-pagination>
                  </ul>
                </div>
              </div>
            </div>
              </b-modal>

</form>

</Layout>
</template>
<script>
import Multiselect from "vue-multiselect";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueGoogleAutocomplete from 'vue-google-autocomplete';
import   helperFile   from '../../../helpers/helper';
import { required } from "vuelidate/lib/validators";
export default {
  page: {
    title: "Add Principal Branch",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Multiselect,
    Layout,
    PageHeader,
    VueGoogleAutocomplete,

  },
  data() {
    return {
      title: "Add Principal Branch",
      items: [
        {
          text: "Principal Branch",
          href: "/",
        },
        {
          text: "Add",
          active: true,
        },
      ],

      branchName: null,
      foodType: [],
      status :'',
      submitted: false,
      address: '',
      principleBranchData:[],
      alertMsg :"Principal Branch Registered Successfully!",
      principles:[],
      branches:[],
      managers:[],
      textarea: "",
      imageData: "",
      showDismissibleAlert :false,
      loading: false,
      principleBranchID : 0,
      isEdit : false,
      corporateBranchTypeArr:[
        {"typeID":1,"typeName":"Corporate Branch"},
        {"typeID":2,"typeName":"Sub-Corporate Principal"},
      ],
      principleID:"",
      disabled:true,
      disabledOwner:false,
      managerID:"",
      empType:false,
      /////// table for branch ////
      tableData: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: [],
      sortBy: "age",
      sortDesc: false,
      selectMode: 'multi',
      selected: [],
      dishData:"",
      fields: [
        {
          key: "selected",

          sortable: false,
        },
        {
          key: "corporateName",
          sortable: true,
        },
        {
          key: "address",

          sortable: true,
        },
      ],

      posts:{
        branchID : "",
        address:"",
        addressInput:"",
        state:"",
        country:"",
        pincode:"",
        city:"",
        latitude:"",
        longitude:"",
        gstName:"",
        uniqueIdentifier:"",
        gstNO:"",
          }
    };
  },

  validations: {
    posts: {
      branchID:{
        required,
      },
      addressInput:{
        required,
      },
    },
    principleID:{
      required,
    },
  },
  methods:{
    getAddressData: function (addressData, placeResultData) {
      this.address = addressData;
      if(placeResultData){
        this.getAllFields = helperFile.getAddressFieldsFromPlacesApi;
        this.getAllFields(placeResultData);
        this.posts.addressInput = placeResultData.formatted_address;
        this.posts.latitude = placeResultData.geometry.location.lat();
        this.posts.longitude = placeResultData.geometry.location.lng();
      }
    },
    addNewAddress(){
      this.posts.address = this.posts.addressInput;
    },
    onchangePrinciple(){
      this.axios.post(this.$loggedRole+"/getPrincipleAdminByID",{'principleID':this.principleID.principleID})
          .then((result)=>{
            this.managers = result.data.data;

          });
    },
    registerNew(){
      // this.posts.showAlert = 1;
      if(this.disabled){
        this.disabled = false;
        this.disabledOwner = true;
        this.managerID = "";
      }else{
        this.disabled = true;
        this.disabledOwner = false;
        this.posts.adminEmail = "";
        this.posts.adminPhone = "";
        this.posts.adminName = "";
      }
    },

    submitPrincipleBrachForm(e){
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        return;
      } else {
        this.loading = true;
        let formData = new FormData();

               formData.append('principleBranchID',this.principleBranchID);
        formData.append('addressInput',this.posts.addressInput);
        formData.append('address',this.posts.address);
        formData.append('state',this.posts.state);
        formData.append('city',this.posts.city);
        formData.append('pincode',this.posts.pincode);
        formData.append('country',this.posts.country);
        formData.append('latitude',this.posts.latitude);
        formData.append('longitude',this.posts.longitude);
        formData.append('principleID',this.principleID.principleID);
        formData.append('gstNO',this.posts.gstNO);
        formData.append('gstName',this.posts.gstName);
        formData.append('uniqueIdentifier',this.posts.uniqueIdentifier);
        formData.append('branchID',this.posts.branchID.branchID);
        formData.append('branchIDs',JSON.stringify(this.selected));
        e.preventDefault();
        let apiName = this.$loggedRole+"/addPrincipleBranch";
        let routerPush = "principleBranchTable";
        if(this.isEdit==true){
          apiName = this.$loggedRole+"/updatePrincipleBranchForm";
          routerPush = "principleBranchTable";
        }

        this.axios.post(apiName,  formData)
            .then((result)=>{
              this.loading = false;
              this.$swal({
                text: result.data.message,
                icon: "success",
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 2000
              });
              setTimeout(() => {
                this.$router.push({name: routerPush});
              },2000);
            })
            .catch((error)=> {
              this.loading = false;
              this.$swal({
                text: error.response.data.message,
                icon: "error",
              });
            });
      }
    },
    getAllBranchDetails(){
      this.axios.get(this.$loggedRole+"/getAllBranchDetails")
          .then((result)=>{
            this.branches = result.data.data;


          });
    },


    getPrincipleList(){
      this.axios.post(this.$loggedRole+"/getPrincipleList")
          .then((result)=>{
            this.principles = result.data.data;

          });
    },
    getNearByCorporateBranches(){
      this.selected = [];
      this.axios.post(this.$loggedRole+"/getNearByCorporateBranches",{
        'latitude':this.posts.latitude,
        'longitude':this.posts.longitude,
        principleBranchID : this.principleBranchID,
      })
          .then((result)=>{
            this.tableData = result.data.data;

            for(var i=0; i<=this.tableData.length; i++){
              if(this.tableData[i].rowSelected==true){
                this.selected.push(this.tableData[i].corpBranchID);
              }
            }

          });
    },

    previewImage: function(event) {
      // Reference to the DOM input element
      var input = event.target;
      // Ensure that you have a file before attempting to read it
      if (input.files && input.files[0]) {
        // create a new FileReader to read this image and convert to base64 format
        var reader = new FileReader();
        // Define a callback function to run, when FileReader finishes its job
        reader.onload = (e) => {
          // Note: arrow function used here, so that "this.imageData" refers to the imageData of Vue component
          // Read image as base64 and set to imageData
          this.imageData = e.target.result;
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0]);
      }
    },

    openModal(){
      //alert();
      this.$root.$emit("bv::show::modal", "modalBranches");
      this.getNearByCorporateBranches();
    },


    onRowSelected(items) {
      this.selected = items;
      // console.log(this.selected);
    },
    selectAllRows() {
      this.selected = [];
      // alert();
      if (!this.selectAll) {

        for (let i in this.tableData) {
          this.selected.push(this.tableData[i].corpBranchID);
        }
      }
    },
    clearSelected() {
      this.selected = [];
    },
    selectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.selectRow(2)
    },
    unselectThirdRow() {
      // Rows are indexed from 0, so the third row is index 2
      this.$refs.selectableTable.unselectRow(2)
    },

    ///// data table methods
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    getPrincipleBranchDataByID(){
      this.axios.post(this.$loggedRole+"/getPrincipleBranchDataByID",
          {'principleBranchID':this.principleBranchID})
          .then((result)=>{
            this.principleBranchData = result.data.data;
            this.principleID = this.principleBranchData.principleName;
            this.posts.gstNO = this.principleBranchData.gstNO;
            this.posts.gstName = this.principleBranchData.gstName;
            this.posts.address = this.principleBranchData.address;
            this.posts.state = this.principleBranchData.state;
            this.posts.city = this.principleBranchData.city;
            this.posts.pincode = this.principleBranchData.pincode;
            this.posts.country =  this.principleBranchData.country;
            this.posts.addressInput = this.principleBranchData.address;
            this.posts.branchID = this.principleBranchData.branch;
            this.posts.latitude = this.principleBranchData.latitude;
            this.posts.longitude = this.principleBranchData.longitude;
            this.getNearByCorporateBranches();
          });
    }
  },

  mounted(){
    this.getAllBranchDetails();
    this.getPrincipleList();
    this.$refs.address.focus();

    if(this.$route.params.type=="edit"){
      if(this.$route.params.id){
        sessionStorage.setItem('principleBranchID',this.$route.params.id);
      }
    }else{
      sessionStorage.setItem('principleBranchID',0);
    }
    this.principleBranchID = sessionStorage.getItem('principleBranchID')
    if(this.principleBranchID>0){
      this.getPrincipleBranchDataByID(this.principleBranchID);
      this.isEdit = true;

    }

  },
  computed: {
    /**
     * Total no. of records
     */
    rows() {
      return this.tableData.length;
    },
  },
  middleware: "authentication",
};
</script>
<style lang="scss" scoped>

img.preview {
    width: 100px;
    height: 100px;
    background-color: white;
    border: 1px solid #DDD;
    padding: 5px;
    object-fit: contain;
    margin-top: 6px;
}

.alertMsg{
  position: fixed;
    right: 0px;
    z-index: 500;
    top: 79px;
    width: 22%;
}
</style>


